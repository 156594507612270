// @ts-nocheck

type formType = 'personnel' | 'currentWork' | null
const initState = () => ({
  user: {
    id: null,
    name: '',
    type: null as formType,
  },
  work: {
    id: null,
  },
  export: null,
});

const mutations = {
  setUserDetail (state, payload) {
    state.user = payload;
  },
  setWorkId (state, id) {
    state.work.id = id;
  },
  setExport (state, payload) {
    state.export = payload;
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
};
