
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import LeftNav from '@/components/LeftNav/menus.vue';
import { useStore } from 'vuex';
import { listenKey } from '@/common/listenKey';

export default defineComponent({
  components: {
    LeftNav,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isFullScreen = ref<null | boolean>(null); // 是否为登录页
    const userName = computed(() => store.getters['user/getCurrentUserDetail'].name); // 用于展示的用户名
    const fullScreenList = ['Login', 'Export', 'Complexity'];

    // 初始化用户信息
    const initHeader = () => {
      store.dispatch('user/getUserDetail');
    };
    onMounted(() => {
      // 监听键盘事件,坚持ctrl/command
      window.addEventListener('keydown', listenKey);
      window.addEventListener('keyup', listenKey);
    });
    // 当参数更改时获取用户信息
    watch(
      () => route.name,
      async newName => {
        const isFull = fullScreenList.includes(newName as string);
        isFullScreen.value = isFull;
        !isFull && initHeader();
      },
    );
    onBeforeUnmount(() => {
      // 卸载监听事件
      window.removeEventListener('keydown', listenKey);
      window.removeEventListener('keyup', listenKey);
    });
    return {
      isFullScreen,
      userName,
    };
  },
});
