import { exchangeToken } from '@/api';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import cache from '../common/cache';

const routes: Array<RouteRecordRaw> = [
  // 任务列表首页
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "index" */ '../modules/task/index.vue'),
  },
  // 404重定向到首页
  {
    path: '/:pathMatch(.*)',
    redirect: '/',
  },
  // 扫码登录
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../modules/login/index.vue'),
  },
  // 创建任务
  {
    path: '/create',
    name: 'Create',
    component: () => import(/* webpackChunkName: "about" */ '../modules/task/create.vue'),
  },
  // 查看成员
  {
    path: '/member',
    name: 'Member',
    component: () => import(/* webpackChunkName: "about" */ '../modules/task/member.vue'),
  },
  // 查看阶段
  {
    path: '/stage',
    name: 'Stage',
    component: () => import(/* webpackChunkName: "about" */ '../modules/task/stage.vue'),
  },
  // 查看跟进
  {
    path: '/follow',
    name: 'Follow',
    component: () => import(/* webpackChunkName: "about" */ '../modules/task/follow.vue'),
  },
  // 新增跟进
  {
    path: '/create-follow',
    name: 'CreateFollow',
    component: () => import(/* webpackChunkName: "about" */ '../modules/task/create-follow.vue'),
  },
  // 人员列表
  {
    path: '/personnel',
    name: 'PersonnelList',
    component: () => import(/* webpackChunkName: "about" */ '../modules/personnel/index.vue'),
  },
  // 人员跟进
  {
    path: '/personnel-follow',
    name: 'personnelFollow',
    component: () => import(/* webpackChunkName: "about" */ '../modules/personnel/personnelFollow/personnel-follow.vue'),
  },
  // 当前任务列表
  {
    path: '/current-work',
    name: 'CurrentWork',
    component: () => import(/* webpackChunkName: "about" */ '../modules/personnel/current-work.vue'),
  },
  // 导出当前任务
  {
    path: '/export',
    name: 'Export',
    component: () => import(/* webpackChunkName: "about" */ '../modules/personnel/export.vue'),
  },
  // 复杂度列表
  {
    path: '/complexity',
    name: 'Complexity',
    component: () => import(/* webpackChunkName: "about" */ '../modules/complexity/index.vue'),
  },
  // 任务BUG情况列表页
  {
    path: '/taskBug',
    name: 'TaskBug',
    component: () => import(/* webpackChunkName: "about" */ '../modules/task/taskBug/index.vue'),
  },
  // 提测BUG情况列
  {
    path: '/testStageBug',
    name: 'TestStageBug',
    component: () => import(/* webpackChunkName: "about" */ '../modules/personnel/testStageBug/index.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _, next) => {
  const tokenKey = 'access-token';
  // 扫码登陆返回携带access-token
  const accessToken = Reflect.get(to.query, tokenKey);
  if (accessToken) {
    // 这里首先储存的是临时token 用于axios绑定header去换取正式的token
    cache.setLocalStorageData('accessToken', accessToken);
    const { code, data } = await exchangeToken();
    if (code === 0) {
      // 此时储存获取到的系统内正式的token
      cache.setLocalStorageData('accessToken', data['access-token']);
      // 去除掉query参数里的access-token
      const query = { ...to.query };
      if (Reflect.deleteProperty(query, tokenKey)) {
        next({ path: to.path, query });
        return;
      }
    }
  }
  next();
});

// 导航守卫 执行ctrl/command 跳转劫持
router.beforeEach((to, form, next) => {
  if (window.$lx_ctrlKey) {
    const url = window.location.origin + to.fullPath;
    window.open(url);
    return false;
  }
  next();
});

export default router;
