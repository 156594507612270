import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app-container" }
const _hoisted_2 = { class: "container-header__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_left_nav = _resolveComponent("left-nav")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isFullScreen && _ctx.userName)
      ? (_openBlock(), _createBlock(_component_el_container, {
          key: 0,
          class: "container"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, { class: "container-header" }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "container-header__title" }, " 敏捷平台 ", -1)),
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.userName), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_container, { class: "container-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_aside, {
                  width: "200px",
                  style: {"background-color":"rgb(238, 241, 246)"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_left_nav, { page: "app" })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_main, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_view)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_ctx.isFullScreen)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
        : _createCommentVNode("", true)
  ]))
}