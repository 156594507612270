
import {
  defineComponent, PropType, reactive, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Configs } from '@/types';
import config from './config';

export default defineComponent({
  name: 'LeftNav',
  props: {
    // 配置文件字段名
    page: {
      type: String as PropType<Configs>,
      required: true,
      default: () => 'app',
    },
  },
  setup(props) {
    const [route, router] = [useRoute(), useRouter()];
    const { page } = reactive(props);
    const menuList = config[page]; // 获取配置相关的菜单列表
    const currentPath = ref<string>(route.fullPath);
    const defaultOpened = menuList.map((_, index) => `${index}`); // 默认菜单全部展开
    const jump = (...args: [string, string[], any]) => {
      const [index, path] = args[1];
      const { children } = menuList[+index];
      const { blank: isBlank = false } = children.find(child => child.index === path) || {};
      isBlank
        ? window.open(router.resolve(path).href, '_blank')
        : router.push(path);
    };

    watch(
      () => route.fullPath,
      newPath => {
        currentPath.value = newPath;
      },
    );

    return {
      defaultOpened,
      currentPath,
      jump,
      menuList,
    };
  },
});
