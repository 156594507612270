import { ElMessage } from 'element-plus';
import _ from 'lodash';

type type = 'success' | 'warning' | 'info' | 'error' | undefined;

/**
 * @description 弹出通知消息
 * @param {string} message
 * @param {type} [type='success']
 */
export const Message = (message: string, type: type = 'success') => {
  ElMessage({
    type,
    message,
  });
};

/**
 * @description 处理对象参数值，排除对象参数值为null，并返回一个新对象
 */
export const dealObjectValue = (obj: any) => {
  const param: {[key: string]: any} = {};
  if (obj === null || obj === undefined || obj === '' || obj.constructor !== Object) return obj;
  Object.keys(obj).forEach(key => {
    if (obj.constructor !== Object) {
      param[key] = dealObjectValue(obj[key]);
    } else if (obj[key] !== null) {
      param[key] = obj[key];
    }
  });
  return param;
};

/**
 * @description 过滤枚举值并取出相应的值
 * @param {Array} enumArr
 * @param {String } key
 * @param keyValue
 */
export const getEnumArrForKey = (enumArr: Array<{ value: any;label: any; [key: string]: any }>, key: string, keyValue: any) => enumArr.find(el => el[key] === keyValue);

/**
 * @description 转换浮点数  整数集会转换成浮点数 如 45 convert 4.5
 * @param { number } conversionNum
 * @param { number } multiple   转换倍数，默认10
 * @return {string, number}
 */
export const convertFloatInInt = (conversionNum: number, multiple = 10) => {
  conversionNum = +conversionNum;
  if (!_.isNumber(+conversionNum)) return '';
  return _.divide(conversionNum, multiple);
};
/**
 * @description 转换整数集 如 3.2 convert 32
 * @param { number } conversionNum
 * @param { number } multiple 转换倍数，默认10
 * @return {string, number}
 */
export const convertIntInFloat = (conversionNum: number, multiple = 10) => {
  conversionNum = +conversionNum;
  if (!_.isNumber(+conversionNum)) return '';
  return _.multiply(conversionNum, multiple);
};
