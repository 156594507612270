/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck
import { NormalResponse } from '@/types';
import { ExchangeToken, GetCurrentUserInfo, GetLoginUrl } from '@/types/login/login';
import { GetUserWork } from '@/types/personnel/current-work';
import { GetUserRoleList, KpiList } from '@/types/personnel/personnel';
import { GetTapdTaskParams, GetTapdResponse, UpdateResponse } from '@/types/task/create';
import { GetUserStage, GetFollowUpList, GetFollowDetail } from '@/types/task/followup';
import { GetMemberList, GetTaskMember } from '@/types/task/member';
import { GetStage, GetStageList } from '@/types/task/stage';
import { GetWorkList } from '@/types/task/work';
import { WorkFollowUps } from '@/types/personnel/work-followups';
import { ComplexityData } from '@/types/complexity/complexity';
import { TaskBugListResData } from '@/modules/task/taskBug/type';
import axios, { AxiosResponse } from 'axios';
import { BugScoreTips, TestStageBugListPagination } from '@/modules/personnel/testStageBug/type';
import {
  query, create, del, patch, put,
} from './axios';

const baseComplexityUrl = 'https://static.lixinio.com/frontend/complexity'; // 复杂度OSS请求路径

// TAPD模块
export const getTapdTask = (params: GetTapdTaskParams) => query<GetTapdResponse>('/tapd/iterates', params); // 查找tapd任务
export const getTapdBugs = (params) => query<GetTapdResponse>('/tapd/bugs', params); // 查找tapd bugs
export const createTapdTask = (id: number, body: any) => create<UpdateResponse>(`/tapd/iterate/${id}/work`, body); // 新建tapd任务
export const createTapdBug = (id: number, body: any) => create<UpdateResponse>(`/tapd/bug/${id}/work`, body); // 新建tapd任务

// 用户模块
export const getUser = params => query<GetMemberList>('/user', params); // 查找用户
export const getUserWork = params => query<GetUserWork>('/user/work', params); // 查找用户任务列表
export const exportUserWork = params => query<NormalResponse<string>>('/user/work/export', params); // 查找用户任务列表
export const getUserRoleList = () => query<GetUserRoleList>('/user/roles'); // 获取用户角色列表
export const getUserWorkFollowups = params => query<NormalResponse<WorkFollowUps>>('/follow_up/follow_ups', params); // 获取用户跟进信息列表
export const getUserKpiList = params => query<NormalResponse<{data: Array<KpiList>}>>('/follow_up/kpis', params); // 获取该角色的KPI项

// 工作任务模块
export const getWorkList = params => query<GetWorkList>('/work/', params); // 查找工作任务列表
export const createCustomWork = (body: any) => create<UpdateResponse>('/work/', body); // 创建工作任务
export const updateWorkTime = (id, body) => patch<UpdateResponse>(`/work/${id}/time`, body); // 修改工期
export const deleteWork = id => del<UpdateResponse>(`/work/${id}`); // 删除工作任务
export const finishWork = (id, body) => patch<UpdateResponse>(`/work/${id}`, body); // 完成工作任务

export const createFollowUp = (body) => create<UpdateResponse>('/follow_up/follow_ups', body); // 创建任务跟进备注
export const deleteFollowUp = (id, cid) => del<UpdateResponse>(`/work/${id}/followup/${cid}`); // 删除任务跟进备注
export const getFollowDetail = (id, cid) => query<GetFollowDetail>(`/work/${id}/followup/${cid}/remark`); // 任务跟进备注详情
export const getFollowUpList = (id, params) => query<GetFollowUpList>(`/work/works/${id}/follow_ups`, params); // 获取任务跟进备注列表

// 阶段模块
export const getStage = params => query<GetStage>('/stage', params); // 查找阶段
export const getUserStage = id => query<GetUserStage>(`/work/${id}/meta`); // 获取跟进任务的用户和阶段
export const updateStage = (id, body) => create<UpdateResponse>(`/work/${id}/stage`, body); // 更新任务阶段
export const deleteStage = (id, sid) => del<UpdateResponse>(`/work/${id}/stage/${sid}`); // 删除任务阶段
export const getStageList = (id, params) => query<GetStageList>(`/work/${id}/stages`, params); // 获取任务阶段列表
export const getPlanEmail = id => query<NormalResponse<{ plan_email: string }>>(`/work/${id}/plan_email`); // 获取排期邮件正文

export const updateTaskMember = (id, body) => create<UpdateResponse>(`/work/${id}/user`, body); // 更新任务参与人
export const deleteTaskMember = (id, uid) => del<UpdateResponse>(`/work/${id}/user/${uid}`); // 删除任务参与人员
export const getTaskMember = (id, params) => query<GetTaskMember>(`/work/${id}/users`, params); // 获取用户参与人

export const updateUserCostOrDifficult = (id, work_user_id, body) => put<NormalResponse<null>>(`/work/works/${id}/users/${work_user_id}`, body); // 更新任务参与人员工作量和难度系数

export const getWorkBugList = (id, params) => query<NormalResponse<TaskBugListResData>>(`/work/works/${id}/user_bugs`, params); // 获取任务的人员bug情况
export const deleteWorkBug = (work_user_id, bug_id) => del<NormalResponse<null>>(`/work/work_users/${work_user_id}/user_bugs/${bug_id}`); // 删除任务参与人员bug情况
export const createWorkBug = (work_id, work_user_id, body) => create<NormalResponse<Null>>(`/work/works/${work_id}/users/${work_user_id}/user_bug`, body); // 创建任务的人员bug情况
export const getWorkUserBugOverviews = body => query<NormalResponse<TestStageBugListPagination>>('/user/work_user_bug_overviews', body); // 用户任务bug情况
export const getBugScoreTips = () => query<NormalResponse<BugScoreTips>>('common/tips'); // 获取用户bug评分提示
// 登陆模块
export const getLoginUrl = params => query<GetLoginUrl>('/auth/login_url', params); // 获取登陆URL
export const exchangeToken = () => create<ExchangeToken>('/auth/exchange_token'); // 临时token换正式token
export const getCurrentUser = () => query<GetCurrentUserInfo>('/auth/current_user'); // 获取当前用户信息

// 复杂度模块
export const getComplexity = name => axios.get<void, AxiosResponse<ComplexityData>>(`${baseComplexityUrl}/${name}/${name}.json`); // 获取复杂度JSON文件
