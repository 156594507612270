import { createLogger, createStore } from 'vuex';
import personnel from './modules/personnel';
import user from './modules/user';
import setting from './modules/setting/index';

const defaultPlugins = [];
process.env.NODE_ENV !== 'production' && defaultPlugins.push(createLogger());
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    personnel,
    user,
    setting,
  },
  plugins: defaultPlugins,
});
