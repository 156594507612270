import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import * as ElementIcons from '@element-plus/icons-vue';

import svgIcon from '@/components/SvgIcon/index.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import auth from './common/auth';
// 导入svgIcon
import './icons/index';

const app = createApp(App)
  .directive('auth', auth)
  .component('svg-icon', svgIcon)
  .use(store)
  .use(router)
  .use(ElementPlus, { locale });

// 统一注册Icon图标组件
Object.keys(ElementIcons)
  .forEach(
    (iconName) => {
      if (Reflect.has(ElementIcons, iconName)) {
        const item = (ElementIcons as any)[iconName];
        (app as any).component(iconName, item);
      }
    },
  );

app.mount('#app');
