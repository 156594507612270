import { StateUser } from './state-types';

export enum MutationsTypes {
  SET_USER_DETAIL = 'setUserDetail',
  SET_WORK_ID = 'setWorkId',
  SET_EXPORT = 'setExport',
  SET_ROLES = 'setRoles',
}

export type PersonnelMutations<S> = {
  [MutationsTypes.SET_USER_DETAIL]: (state: S, payload: StateUser) => any;
  [MutationsTypes.SET_WORK_ID]: (state: S, payload: string) => any;
  [MutationsTypes.SET_EXPORT]: (state: S, payload: string) => any;
}

export type RolesMutations<S> = {
  [MutationsTypes.SET_ROLES]: (state: S, payload: Array<string>) => any;
}
