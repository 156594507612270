import store from '@/store';

function checkPermission(el: any, binding: any) {
  // tapd:https://www.tapd.cn/21753541/prong/stories/view/1121753541001009069?url_cache_key=fd4777f451577e5971a56141ab492c5e&action_entry_type=stories
  // 默认值作为当前版本功能的实现，即只区分是否为管理员，后续如果需要细分权限时需要清除 is:manager
  const { value = ['is:manager'] } = binding;
  const roles = store.getters && store.getters['user/getRoles'];
  if (!Array.isArray(value)) {
    // 当鉴权赋值错误时，在控制台抛出异常，并去除dom节点
    el.parentNode && el.parentNode.removeChild(el);
    throw new Error('need permission! Like v-auth="[\'admin\',\'editor\']"');
  }
  const permissionRoles = value;
  const hasPermission = roles.some((role: any) => permissionRoles.includes(role));
  if (!hasPermission) {
    el.parentNode && el.parentNode.removeChild(el);
  }
}

export default {
  mounted(el: any, binding: any) {
    checkPermission(el, binding);
  },
  update(el: any, binding: any) {
    checkPermission(el, binding);
  },
};
