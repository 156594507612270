// @ts-nocheck
import { getCurrentUser } from '@/api';
import { MutationsTypes } from './mutations-types';

const initState = () => ({
  user: {
    id: null,
    name: '',
  },
  roles: [], // 预备未来加入更细的权限校验
});

const mutations = {
  setUserDetail (state, payload) {
    state.user = payload;
  },
  [MutationsTypes.SET_ROLES] (state, payload) {
    state.roles = payload;
  },
};

const actions = {
  async getUserDetail(context) {
    if (!context.state.user.id) {
      const { code, data } = await getCurrentUser();
      if (code === 0) {
        context.commit('setUserDetail', data);
        data.is_manager && context.commit(MutationsTypes.SET_ROLES, ['is:manager']);
      }
    }
  },
};

const getters = {
  getCurrentUserDetail (state) {
    return state.user;
  },
  getRoles(state: StateUser) {
    return state.roles;
  },
};

export default {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
};
