import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    "default-active": _ctx.currentPath,
    "default-openeds": _ctx.defaultOpened,
    onSelect: _ctx.jump
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (menu, index) => {
        return (_openBlock(), _createBlock(_component_el_sub_menu, {
          key: menu.title,
          index: `${index}`
      
        }, {
          title: _withCtx(() => [
            _createVNode(_component_el_icon, { size: 20 }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(menu.icon)))
              ]),
              _: 2
            }, 1024),
            _createTextVNode(" " + _toDisplayString(menu.title), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item_group, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.children, (child) => {
                  return (_openBlock(), _createBlock(_component_el_menu_item, {
                    key: child.title,
                    index: child.index
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(child.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                }), 128))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["index"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["default-active", "default-openeds", "onSelect"]))
}