// 左侧菜单配置
import { Configs, Menu } from '@/types';

// 主页导航栏
const app: Menu[] = [
  {
    title: '任务管理',
    icon: 'Notebook',
    children: [
      {
        title: '工作任务',
        index: '/',
      }, {
        title: '新增工作任务',
        index: '/create',
      },
    ],
  },
  {
    title: '人员管理',
    icon: 'User',
    children: [
      {
        title: '人员列表',
        index: '/personnel',
      },
      {
        title: '人员跟进列表',
        index: '/personnel-follow',
      },
      {
        title: '当前任务',
        index: '/current-work',
      },
      {
        title: '提测bug情况',
        index: '/testStageBug',
      },
    ],
  },
  {
    title: '圈复杂度管理',
    icon: 'Tickets',
    children: [
      {
        title: '项目列表',
        index: '/complexity?name=corp-master-frontend',
        blank: true,
      },
    ],
  },
];

// 圈复杂度导航栏
const complexity: Menu[] = [
  {
    title: '项目列表',
    icon: 'Tickets',
    children: [
      'corp-master-frontend',
      'boss-frontend',
      'merchant-frontend',
      'ucar-master-frontend',
      'ucma-frontend',
    ].map(title => ({
      title,
      index: `/complexity?name=${title}`,
    })),
  },
];

export default {
  app,
  complexity,
} as {
  [k in Configs]: Menu[];
};
