import { MutationsTypes } from './mutations-types';
// 为 store state 声明类型
export interface State {
  scoreHiddenStatus: boolean;
}
const initState = () => ({
  scoreHiddenStatus: true,
});

const mutations = {
  [MutationsTypes.SWITCH_SCORE_HIDE_STATUS] (state: State, payload: boolean) {
    state.scoreHiddenStatus = payload || !state.scoreHiddenStatus;
  },
};

const getters = {
  getScoreHiddenStatus (state: State) {
    return state.scoreHiddenStatus;
  },
};

export default {
  namespaced: true,
  state: initState,
  mutations,
  getters,
};
