/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import cache from '@/common/cache';
import { dealObjectValue, Message } from '@/common/utils';
import router from '@/router';
// create an axios instance
const service = axios.create({
  baseURL: '/api/v1', // api 的 base_url
  withCredentials: true, // 跨域请求时发送 cookies
  timeout: 10000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['access-token'] = cache.getLocalStorageData('accessToken');
    // 剔除null字符串
    config.data = dealObjectValue(config.data);
    return config;
  },
  error => {
    // Do something with request error
    console.error(error); // for debug
    Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过 xmlhttprequest 来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  async response => {
    const { data = {} } = response;
    const { code, message, detail } = data;
    if (code !== 0) {
      Message(`Code:${code} Error: ${message} Detail: ${detail || 'no detail'}`, 'error');
    }
    return data;
  },
  error => {
    const { response } = error;
    if (response) {
      const { status } = response;
      switch (status) {
        case 401:
          Message('未登录', 'error');
          router.push(`/login?redirect=${window.location.href}`);
          break;
        default:
          Message(`Error: ${status} 请求发生异常`, 'error');
          break;
      }
    }
    return {
      code: -1,
      message: '请求发生异常',
    };
  },
);

export const query = <T>(url: string, params?: any): Promise<T> => new Promise((resolve, reject) => {
  service
    .get<void, T>(url, { params })
    .then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
});

export const create = <T>(url: string, body?: any): Promise<T> => new Promise((resolve, reject) => {
  service
    .post<void, T>(url, body)
    .then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
});

export const patch = <T>(url: string, body?: any): Promise<T> => new Promise((resolve, reject) => {
  service
    .patch<void, T>(url, body)
    .then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
});

export const del = <T>(url: string): Promise<T> => new Promise((resolve, reject) => {
  service
    .delete<void, T>(url)
    .then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
});

export const put = <T>(url: string, body?: any): Promise<T> => new Promise((resolve, reject) => {
  service
    .put<void, T>(url, body)
    .then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
});
